type DecodedJSON = {
  hash: string;
  metadata: { typeEn: string; typeZh: string };
  nameEn: string;
  nameZh: string;
  type: string;
};

// https://github.com/aaasssdddwww/back-home-safe/pull/7
export const qrDecode = (input: string): string => {
  if (!input.startsWith("HKEN:")) return "";
  const json: DecodedJSON = JSON.parse(window.atob(input.substring(14)));
  console.log(json);

  const { nameZh, nameEn } = json;
  // const ezh = escape(nameZh);
  // const een = escape(nameEn);

  let result = nameZh;
  if (!nameZh || nameZh === '' || nameZh === '\n' || nameZh === '\\n') result = nameEn;
  if (!nameEn || nameEn === '' || nameEn === '\n' || nameEn === '\\n') result = '';

  return decodeURIComponent(escape(result));
};
