import styled from "styled-components";

export const ShowPlace = styled.div`
  text-align: center;
  font-size: 32px;
  width: auto;
  word-wrap: break-word;
  overflow: visible;
  background-color: transparent;
  border: 0;
  outline: none;
  margin: 8px 16px;
  color: #fed426;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);

  &:focus {
    outline: none;
  }
`;
